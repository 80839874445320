import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ShadowText } from 'components/styled/typography'
import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import RenderHtml from '../components/shared/renderHtml'
import { config } from '../config'
import ProfilePic from 'images/profile_placeholder.png'
import { formatDate, formatName, getImageUrl } from '../Utils'
import axios from 'axios'


const Container = styled.div`
  display: flex;
  padding: 40px 100px 40px 100px;
  min-height: 800px;

  .mh-400 {
    max-height: 400px;
  }

  img {
    object-fit: cover;
  }

  .mh-250 {
    max-height: 250px;
  }

  .my-50px {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  h3 {
    line-height: 34px;
  }

  .lh-24px {
    line-height: 24px;
  }

  .mt-8px {
    margin-top: 8px;
  }

  .border-bottom {
    border-bottom: 1px solid #bababa;
  }

  .pb-5rem {
    padding-bottom: 5rem;
  }

  .mb-5rem {
    margin-bottom: 5rem;
  }

  .title {
    font-size: 20px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
  }

  .text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
  }

  .fs-21px {
    font-size: 21px;
  }

  .fs-18px {
    font-size: 18px;
  }

  .fs-22px {
    font-size: 22px;
  }

  img.author {
    margin-right: 1rem;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    object-fit: cover;
  }

  .d-none {
    display: none;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .px-mobile-0 {
      padding-left: 0;
      padding-right: 0;
    }

    .px-15px {
      padding-left: 15px;
      padding-right: 15px;
    }

    .d-mobile-none {
      display: none;
    }

    .d-mobile-block {
      display: block;
    }

    .border-bottom {
      border-bottom: 1px solid transparent;
    }

    .pb-5rem {
      padding-bottom: 1rem;
    }

    .mb-5rem {
      margin-bottom: 1rem;
    }

    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 16px;
    padding-bottom: 16px;
    .title {
      font-size: 14px;
    }

    .fs-21px {
      font-size: 18px;
    }

    .fs-22px {
      font-size: 19px;
    }

    padding: 16px;
    img.author {
      margin-right: 0.5rem;
      height: 35px;
      width: 35px;
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h3 {
      line-height: 24px;
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  max-width: 1520px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 100%;

  p {
    font-weight: 400;
    line-height: 36px;
    font-size: 27px;
    letter-spacing: -0.1px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`
const PostContent = styled.div`
  img {
    max-width: 100%;
  }
`
const ColumnHalf = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  padding-left: 15px;
  padding-right: 15px;

  &.mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  h3.fs-30px {
    line-height: 36px !important;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding-left: 30px;
    padding-right: 30px;
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const ColumnFour = styled.div`
  flex: 0 0 auto;
  width: 33.33333333%;
  padding-left: 15px;
  padding-right: 15px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 auto;
    width: 50%;
    margin-top: 40px;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    .mt-7px {
      margin-top: 7px;
    }

    margin-top: 0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-top: 1px solid #bababa;
    flex-direction: row;
    display: flex;
    align-items: start;
    img {
      width: 60px;
      height: auto;
      margin-right: 1rem;
    }

    h3.fs-22px {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
`

const BlogPageInner = ({ pageContext }) => {

  const [recent, setRecent] = useState([])

  const { post } = pageContext

  useEffect(async () => {
    try {
      let recentPosts = (await axios.get(`${config.API_BASE_URL}/posts?enabled=true&_sort=sortPosition:DESC&_limit=4&_start=${recent.length}`)).data
      recentPosts = recentPosts.filter(p => {
        return p.id !== post.id
      })
      recentPosts = recentPosts.slice(0, 3)
      setRecent(recentPosts)
      await axios.post(`${config.API_BASE_URL}/posts/${post.id}/view`, {})
    } catch (e) {

    }
  }, [])


  return (
    <Layout title={`BuildUp: ${post.title}`} ogImage={post.image ? getImageUrl(post.image) : null} hideTitlePrefix={true}>
      <div className='page'>
        <Container>
          <FixedContainer>
            <DescriptionContainer>
              <Row className='border-bottom pb-5rem mb-5rem'>
                <ColumnHalf className='mx-auto px-mobile-0'>
                  {post.image &&
                  <img src={getImageUrl(post.image)} className='w-100 img-fluid mh-400' alt='' />
                  }
                </ColumnHalf>
                <ColumnHalf className='mx-auto'>
                  <h3 className='fs-30px'>{post.title}</h3>
                  <div className='d-flex align-items-center px-0'>
                    <img src={post.author?.image ? `${config.CDN_BASE_URL}/${post.author.image}` : ProfilePic}
                         className='author' alt='' />
                    <div>
                      <h6 className='title'>{formatName(post.author)}</h6>
                      <p className='text'>{formatDate(post.date)} - {post.duration} min read</p>
                    </div>
                  </div>
                  <PostContent>
                    <RenderHtml html={post.content} tag={'div'} />
                  </PostContent>
                </ColumnHalf>
              </Row>
              {recent.length > 0 &&
              <>
                <div className='d-mobile-none'>
                  <ShadowText highlight>
                    <h3>Posts you may like</h3>
                  </ShadowText>
                </div>
                <div className='d-mobile-block d-none  px-15px'>
                  <h3 className='fs-22px'>Posts you may like</h3>
                </div>
              </>
              }
              <Row className='mb-100px px-15px'>
                {recent.map(post => {
                  return <ColumnFour key={`post-${post.id}`}>
                    <a href={`/blog/${post.key}`}>
                      <img
                        src={getImageUrl(post.image)}
                        className='w-100 img-fluid mh-250 mt-7px' alt='' />
                    </a>
                    <div>
                      <a href={`/blog/${post.key}`} className='text-dark text-decoration-none'>
                        <h3 className='fs-22px mt-0 mb-0'>{post.title}</h3>
                      </a>
                      <p className='fs-18px mt-8px lh-24px d-mobile-none'>
                        {post.excerpt}
                      </p>
                      <div className='d-flex align-items-center px-0'>
                        <img
                          src={post.author.image ? getImageUrl(post.author.image) : ProfilePic}
                          className='author  d-mobile-none' alt='' />
                        <div>
                          <h6 className='title  d-mobile-none'>{formatName(post.author)}</h6>
                          <p className='text'>{formatDate(post.date)} - {post.duration} min read</p>
                        </div>
                      </div>
                    </div>
                  </ColumnFour>
                })}
              </Row>
            </DescriptionContainer>
          </FixedContainer>
        </Container>
      </div>
    </Layout>
  )
}

export default BlogPageInner
